import NotoSansJP from "@/fonts/Noto_Sans_JP/NotoSansJP-Medium.ttf"
import * as api from "@/lib/api"
import { IOperator, IOption, IReport, IReportQuestion, IReportComments, IOperatorOther } from "@/lib/interface"
import { errorHandle } from "@/lib/system"
import { parseComments, parseOperatorsOthers } from "@/lib/utils"
import jsPDF from "jspdf"
import React, { useEffect, useReducer, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { ACTIONTYPE, initialReport, reducerFunc } from "./form-func"

function App() {
  const params = useParams<any>()
  const [report, reportDispatch] = useReducer<React.Reducer<IReport, ACTIONTYPE>>(reducerFunc, initialReport)
  const [weathers, setWeathers] = useState<IOption[]>([])
  const [contracts, setContracts] = useState<IOption[]>([])
  const [constructions, setConstructions] = useState<IOption[]>([])
  const [constructionDetails, setConstructionDetails] = useState<IOption[]>([])
  const [comment, setComment] = useState<IReportComments[]>([])
  const [request_comment, setRequestComment] = useState<IReportComments[]>([])
  const [operatorsOthers, setOperatorsOthers] = useState<IOperatorOther[]>([])
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    getOptions()
    if (params.id) {
      getReport(params.id)
    }
  }, [])

  const getReport = async (id: string) => {
    try {
      const reportJson: any = await api.getReportDb(id)
      if (reportJson?.data) {
        reportDispatch({
          type: "SET_REPORT",
          payload: reportJson.data.json,
        })
      }
    } catch (err) {
      console.log(errorHandle(err))
    }
  }
  useEffect(() => {
    if (report?.operators) {
      report.operators.map((operator: IOperator) => {
        if (operator.operator_id === "8855b643-775c-0c5b-0456-42c3d5b799e3" && operator.operator_other) {
          const parsedOperator = parseOperatorsOthers(operator.operator_other)
          setOperatorsOthers(parsedOperator)
        }
      })
    }
  }, [report.operators])

  useEffect(() => {
    if ("string" === typeof report.comment) {
      const parsedComment = parseComments(report?.comment)
      setComment(parsedComment)
    }
    if ("string" === typeof report.request_comment) {
      const request_comment = parseComments(report?.request_comment)
      setRequestComment(request_comment)
    }
  }, [report.comment, report.request_comment])

  const getOptions = async () => {
    try {
      const weatherJson: any = await api.getWeathersDb()
      if (weatherJson?.data) {
        setWeathers(weatherJson?.data.json)
      }
      const contractJson: any = await api.getContractsDb()
      if (contractJson?.data) {
        setContracts(contractJson?.data.json)
      }
      const constructionJson: any = await api.getConstructionsDb()
      if (constructionJson?.data) {
        setConstructions(constructionJson?.data.json)
      }
      const constructionDetailJson: any = await api.getConstructionDetailsDb()
      if (constructionDetailJson?.data) {
        setConstructionDetails(constructionDetailJson?.data.json)
      }
    } catch (err) {
      console.log(errorHandle(err))
    }
  }

  const pdhDownloadHandler = async () => {
    let htmlWidth = 2280
    let pdfWidth = 420
    let pageFormat = "a3"
    let pageOrientation = "l"
    // 項目が少なく１枚で収まるときの処理
    if (contentRef.current && contentRef.current.scrollWidth < 1100) {
      htmlWidth = 1100
      pdfWidth = 210
      pageFormat = "a4"
      pageOrientation = "p"
    }
    // PDFファイルするHTML要素を取得する
    const target = document.getElementById("sanki-pdf")
    if (target === null) return
    // jsPDFインスタンスを生成する
    const pdf = new jsPDF({
      orientation: pageOrientation as "l" | "p" | "portrait" | "landscape",
      unit: "mm",
      format: pageFormat,
      putOnlyUsedFonts: true,
      compress: true,
    })
    // フォントを追加する
    pdf.addFileToVFS("NotoSans.ttf", NotoSansJP)
    pdf.addFont("NotoSans.ttf", "NotoSans", "normal")
    pdf.setFont("NotoSans")
    // HTML要素からPDFを生成する
    pdf.html(target, {
      callback: (pdf) => {
        pdf.save("report.pdf")
      },
      width: pdfWidth,
      windowWidth: htmlWidth,
      autoPaging: false,
      fontFaces: [
        {
          src: [{ url: NotoSansJP, format: "truetype" }],
          family: "NotoSans",
        },
      ],
    })
  }
  const totalOperatorsOther = operatorsOthers.reduce((total, operator) => total + (operator.other_number ?? 0), 0)
  const totalOperators =
    report?.operators?.reduce((acc: number, operator: IOperator) => {
      if (operator.operator_id === "8855b643-775c-0c5b-0456-42c3d5b799e3") {
        return acc + Number(totalOperatorsOther || 0)
      }
      return acc + Number(operator.operator_number || 0)
    }, 0) || 0

  const departmentId = report?.department_id ?? null
  const originalQuestions = report?.questions?.filter(
    (question: IReportQuestion) => question.department_id === departmentId
  )

  return (
    <>
      <div>
        <button className="text-right" onClick={pdhDownloadHandler}>
          PDF出力
        </button>
      </div>
      <div id="sanki-pdf" className="mb-1" style={{ maxWidth: 2200, width: "auto", fontFamily: "'NotoSans'" }}>
        <div className="d-flex pdf-header">
          <p className="mb-1">
            <span>{report?.job_date?.split(" ")[0]}</span>
            <span>
              {
                weathers?.find((weather: IOption) => {
                  return weather.id === report?.weather_id
                })?.name
              }
            </span>
          </p>
          <h5 className="ps-2 text-center">安全衛生点検チェックシート</h5>
        </div>
        <div
          ref={contentRef}
          className="d-flex flex-column flex-wrap pdf-content"
          style={{ fontFamily: "'NotoSans', !important" }}
        >
          <table className="">
            <tbody>
              <tr className="">
                <th className="border-bottom border-right text-center col-1">工事名</th>
                <td className="border-bottom border-right text-center col-1" colSpan={3}>
                  {report?.construction}
                </td>
                <th className="border-bottom border-right text-center col-1" colSpan={1}>
                  請負区分
                </th>
                <td className="border-bottom border-right text-center col-1" colSpan={2}>
                  {
                    contracts?.find((contract: IOption) => {
                      return contract.id === report?.contract_id
                    })?.name
                  }
                </td>
                <th className="border-bottom border-right text-center col-1" colSpan={1}>
                  請負金額
                </th>
                <td className="border-bottom text-center col-1" colSpan={2}>
                  {report?.contract_amount && `${report?.contract_amount} 百万円`}
                </td>
              </tr>
              <tr className="">
                <th className="border-bottom border-right text-center" rowSpan={2}>
                  工期
                </th>
                <td className="border-bottom border-right text-center col-2" colSpan={2} rowSpan={2}>
                  {report?.construction_period_start} ～ {report?.construction_period_end}
                </td>
                <th className="border-bottom border-right text-center col-1" rowSpan={2}>
                  進捗率
                </th>
                <td className="border-bottom border-right text-center" rowSpan={2}>
                  {report?.progress_rate && `${report?.progress_rate} %`}
                </td>
                <td className="border-bottom border-right text-center" colSpan={1} rowSpan={2}>
                  遅←平常→早
                </td>
                <th className="border-bottom border-right text-center" rowSpan={2}>
                  部門
                </th>
                <td className="border-bottom border-right text-center" rowSpan={2}>
                  {report?.department_name}
                </td>
                <th className="border-bottom border-right text-center" rowSpan={2}>
                  工事種類
                </th>
                <td className="border-bottom text-center">
                  {
                    constructions?.find((construction: IOption) => {
                      return construction.id === report?.construction_id
                    })?.name
                  }
                </td>
              </tr>
              <td className="border-bottom text-center">
                {
                  constructionDetails?.find((constructionDetail: IOption) => {
                    return constructionDetail.id === report?.construction_detail_id
                  })?.name
                }
              </td>
              <tr></tr>

              <tr>
                <th className="border-bottom border-right text-center">点検者</th>
                <td className="border-bottom border-right text-center" colSpan={3}>
                  {report?.inspector}
                </td>
                <th className="border-bottom border-right text-center">部長</th>
                <td className="border-bottom border-right text-center" colSpan={1}>
                  {report?.department_manager}{" "}
                </td>
                <th className="border-bottom border-right text-center" colSpan={1}>
                  課長
                </th>
                <td className="border-bottom border-right text-center" colSpan={1}>
                  {report?.section_manager}
                </td>
                <th className="border-bottom border-right text-center" colSpan={1}>
                  現場代理人
                </th>
                <td className="border-bottom text-center" colSpan={1}>
                  {report?.section_manager}
                </td>
              </tr>
              <tr className="border-bottom-none">
                <th className="border-bottom border-right text-center" colSpan={3} rowSpan={2}>
                  当日の作業人員
                </th>
                <td className="border-right border-bottom text-center" colSpan={1}>
                  配管工{" "}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id === "84c44554-a6a0-2d7b-9fda-69d2dee22d7f"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="border-right border-bottom text-center" colSpan={1}>
                  ダクト工{" "}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "25b16904-c2be-d128-00d9-e1998ba20d9f"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="border-right border-bottom text-center" colSpan={1}>
                  電工{" "}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "16e57e17-d7f7-7f42-42ca-931ed8e88010"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="border-right border-bottom text-center" colSpan={1}>
                  塗装工{" "}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "0cddbad8-aa18-bd77-3450-1958487b4a2f"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="border-right border-bottom text-center" colSpan={1}>
                  保温工{" "}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "df728256-4055-2a68-7a7e-d2801a3ef812"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="border-right border-bottom text-center" colSpan={1}>
                  鉄工{" "}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "2cc2080d-edcb-2382-db6a-33ee74b67438"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="text-center border-bottom " colSpan={1}>
                  多能工{" "}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "f8485e9d-f46d-8279-b9f5-702afcfb17b2"
                  )?.operator_number || 0}{" "}
                  人
                </td>
              </tr>
              <tr>
                <td className="border-bottom border-right text-center" colSpan={1}>
                  鳶工{" "}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "acce2d4d-1d64-3ea7-6a23-339d5d0936e8"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="border-bottom border-right text-center" colSpan={1}>
                  外注社員{""}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "93082f69-32c9-9c7d-4657-0c04d165d473"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="border-bottom border-right text-center" colSpan={1}>
                  冷媒配管工{""}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "78d0c8c3-5bcb-a6cb-beda-1aca0f7ff11f"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="border-bottom border-right text-center" colSpan={1}>
                  計装工{""}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "3fcfcfb4-6afa-68df-faa8-53fba6800d40"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="border-bottom border-right text-center" colSpan={1}>
                  当社社員{""}
                  {report?.operators?.find(
                    (operator: IOperator) => operator.operator_id == "301c66f4-3fcf-5ab5-99ae-e2c03b0fd225"
                  )?.operator_number || 0}{" "}
                  人
                </td>
                <td className="border-bottom border-right text-center" colSpan={1}>
                  その他合計: {totalOperatorsOther}人
                </td>
                <td className="border-bottom text-center" colSpan={1}>
                  合計:{totalOperators} 人
                </td>
              </tr>
              <tr>
                <th className="border-right text-center" colSpan={2}>
                  建築会社名（統括安全衛生責任者）
                </th>
                <td className="border-right text-center" colSpan={1}>
                  {report?.construction_company}
                </td>

                <th className="border-right text-center">当日入場協力会社名</th>
                <td className="text-center" colSpan={6}>
                  {report?.partners?.map((partner: string, index: number) => {
                    return (
                      <li key={`partner-${index}`} className="d-inline-block mr-3">
                        {partner.name}
                      </li>
                    )
                  })}
                </td>
              </tr>
            </tbody>
          </table>
          {report?.questions?.some((question: IReportQuestion) => question.department_id === report?.department_id) ? (
            <table className="">
              <thead>
                <tr className="border-bottom">
                  <th className="border-right"></th>
                  <th className="border-right text-center">No</th>
                  <th className="border-right text-center">点検項目</th>
                  <th className="border-right text-center">評価</th>
                  <th className="">指導または是正措置の内容</th>
                </tr>
              </thead>
              <tbody>
                <th className="section-title border-right " rowSpan={(originalQuestions?.length ?? 0) + 1}>
                  {report?.department_name}
                </th>
                {originalQuestions?.map((question: IReportQuestion, index: number) => {
                  return (
                    <tr id={question.inspection_id} key={`question-${index}`}>
                      <td className="border-bottom border-right text-center number">{index + 1}.</td>
                      <td className="border-bottom border-right title">
                        {question.class_name}({question.name})
                      </td>
                      <td className="border-bottom border-right text-center assessment">
                        {question.result_id === "RESULT_CIRCLE"
                          ? "○"
                          : question.result_id === "RESULT_SLASH"
                          ? "/"
                          : question.result_id === "RESULT_CROSS"
                          ? "x"
                          : question.result_id === "RESULT_TRIANGLE"
                          ? "△"
                          : " "}{" "}
                      </td>
                      <td className="border-bottom text-center">
                        {question?.comment?.split("\n")?.map((t: string, index: number) => {
                          return <p key={index}>{t}</p>
                        }) || "-"}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : null}
          {report?.questions?.some(
            (question: IReportQuestion) =>
              question.inspection_id === "d6e0b3a1-5b9e-4b9f-8b9e-1b9e4b9f8b9e" ||
              question.inspection_id === "45e8cd86-c2c3-46a3-90eb-e84f611d3568"
          ) ? (
            <h6>【 事務所内チェック事項 】</h6>
          ) : null}
          {report?.questions?.some(
            (question: IReportQuestion) => question.inspection_id === "39caf377-68c5-492c-81b1-e23426e3364a"
          ) ? (
            <table>
              <thead>
                <tr className="border-bottom">
                  <th className="border-right"></th>
                  <th className="border-right text-center">No</th>
                  <th className="border-right text-center">点検項目</th>
                  <th className="border-right text-center">評価</th>
                  <th className="">指導または是正措置の内容</th>
                </tr>
              </thead>
              <tbody>
                <th
                  className="section-title border-right"
                  rowSpan={
                    report?.questions?.filter(
                      (question: IReportQuestion) => question.inspection_id === "39caf377-68c5-492c-81b1-e23426e3364a"
                    )?.length + 1
                  }
                >
                  A.安全書類関連
                </th>
                {report?.questions
                  ?.filter(
                    (question: IReportQuestion) => question.inspection_id === "39caf377-68c5-492c-81b1-e23426e3364a"
                  )
                  .map((question: IReportQuestion, index: number) => {
                    return (
                      <tr id={question.inspection_id} key={`question-${index}`}>
                        <td className="border-bottom border-right text-center number">{index + 1}.</td>
                        <td className="border-bottom border-right title">
                          {question.class_name}({question.name})
                        </td>
                        <td className="border-bottom border-right text-center assessment">
                          {question.result_id === "RESULT_CIRCLE"
                            ? "○"
                            : question.result_id === "RESULT_SLASH"
                            ? "/"
                            : question.result_id === "RESULT_CROSS"
                            ? "x"
                            : question.result_id === "RESULT_TRIANGLE"
                            ? "△"
                            : " "}{" "}
                        </td>
                        <td className="border-bottom text-center">
                          {question.comment?.split("\n")?.map((t: string, index: number) => {
                            return <p key={index}>{t}</p>
                          }) || "-"}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          ) : null}
          {report?.questions?.some(
            (question: IReportQuestion) => question.inspection_id === "45e8cd86-c2c3-46a3-90eb-e84f611d3568"
          ) ? (
            <table>
              <thead>
                <tr className="border-bottom">
                  <th className="border-right"></th>
                  <th className="border-right text-center">No</th>
                  <th className="border-right text-center">点検項目</th>
                  <th className="border-right text-center">評価</th>
                  <th className="">指導または是正措置の内容</th>
                </tr>
              </thead>
              <tbody>
                <th
                  className="border-right section-title"
                  rowSpan={
                    report?.questions?.filter(
                      (question: IReportQuestion) => question.inspection_id === "45e8cd86-c2c3-46a3-90eb-e84f611d3568"
                    )?.length + 1
                  }
                >
                  B.安全書類関連
                </th>
                {report?.questions
                  ?.filter(
                    (question: IReportQuestion) => question.inspection_id === "45e8cd86-c2c3-46a3-90eb-e84f611d3568"
                  )
                  .map((question: IReportQuestion, index: number) => {
                    return (
                      <tr id={question.inspection_id} key={`question-${index}`}>
                        <td className="border-bottom border-right text-center number">{index + 1}.</td>
                        <td className="border-bottom border-right title">
                          {question.class_name}({question.name})
                        </td>
                        <td className="border-bottom border-right text-center  assessment">
                          {question.result_id === "RESULT_CIRCLE"
                            ? "○"
                            : question.result_id === "RESULT_SLASH"
                            ? "/"
                            : question.result_id === "RESULT_CROSS"
                            ? "x"
                            : question.result_id === "RESULT_TRIANGLE"
                            ? "△"
                            : " "}{" "}
                        </td>
                        <td className="border-bottom text-center">
                          {question.comment?.split("\n")?.map((t: string, index: number) => {
                            return <p key={index}>{t}</p>
                          }) || "-"}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          ) : null}
          {report?.questions?.some(
            (question: IReportQuestion) =>
              question.inspection_id === "16469dbb-db65-454f-bd8e-2f8f2460d5bd" ||
              question.inspection_id === "e1cc7511-8d6c-4250-96ee-3ea6ee3aaec8" ||
              question.inspection_id === "3dcdf21e-4238-4d4c-841d-c66fff865456" ||
              question.inspection_id === "556d94f4-25f6-456e-8c35-b7356a12ed10" ||
              question.inspection_id === "436b1be0-7d05-4c11-afb0-2b3b55e11462" ||
              question.inspection_id === "0c7cf631-48dc-4789-a57d-74723d139bd1" ||
              question.inspection_id === "1df459e5-db25-49ec-958b-f09274c152be"
          ) ? (
            <h6>【 現場内チェック事項 】</h6>
          ) : null}
          {report?.questions?.some(
            (question: IReportQuestion) => question.inspection_id === "16469dbb-db65-454f-bd8e-2f8f2460d5bd"
          ) ? (
            <table>
              <thead>
                <tr className="border-bottom">
                  <th className="border-right"></th>
                  <th className="border-right text-center">No</th>
                  <th className="border-right text-center">点検項目</th>
                  <th className="border-right text-center">評価</th>
                  <th className="">指導または是正措置の内容</th>
                </tr>
              </thead>
              <tbody>
                <th
                  className="section-title border-right"
                  rowSpan={
                    report?.questions?.filter(
                      (question: IReportQuestion) => question.inspection_id === "16469dbb-db65-454f-bd8e-2f8f2460d5bd"
                    )?.length + 1
                  }
                >
                  C. 安全管理体制・作業規律
                </th>
                {report?.questions
                  ?.filter(
                    (question: IReportQuestion) => question.inspection_id === "16469dbb-db65-454f-bd8e-2f8f2460d5bd"
                  )
                  .map((question: IReportQuestion, index: number) => {
                    return (
                      <tr id={question.inspection_id} key={`question-${index}`}>
                        <td className="border-bottom border-right text-center  number">{index + 1}.</td>
                        <td className="border-bottom border-right title">
                          {question.class_name}({question.name})
                        </td>
                        <td className="border-bottom border-right text-center  assessment">
                          {question.result_id === "RESULT_CIRCLE"
                            ? "○"
                            : question.result_id === "RESULT_SLASH"
                            ? "/"
                            : question.result_id === "RESULT_CROSS"
                            ? "x"
                            : question.result_id === "RESULT_TRIANGLE"
                            ? "△"
                            : " "}{" "}
                        </td>
                        <td className="border-bottom text-center">
                          {question.comment?.split("\n")?.map((t: string, index: number) => {
                            return <p key={index}>{t}</p>
                          }) || "-"}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          ) : null}
          {report?.questions?.some(
            (question: IReportQuestion) => question.inspection_id === "e1cc7511-8d6c-4250-96ee-3ea6ee3aaec8"
          ) ? (
            <table>
              <thead>
                <tr className="border-bottom">
                  <th className="border-right"></th>
                  <th className="border-right text-center">No</th>
                  <th className="border-right text-center">点検項目</th>
                  <th className="border-right text-center">評価</th>
                  <th className="">指導または是正措置の内容</th>
                </tr>
              </thead>
              <tbody>
                <th
                  className="section-title border-right"
                  rowSpan={
                    report?.questions?.filter(
                      (question: IReportQuestion) => question.inspection_id === "e1cc7511-8d6c-4250-96ee-3ea6ee3aaec8"
                    )?.length + 1
                  }
                >
                  D. 電気
                </th>
                {report?.questions
                  ?.filter(
                    (question: IReportQuestion) => question.inspection_id === "e1cc7511-8d6c-4250-96ee-3ea6ee3aaec8"
                  )
                  .map((question: IReportQuestion, index: number) => {
                    return (
                      <tr id={question.inspection_id} key={`question-${index}`}>
                        <td className="border-bottom border-right text-center  number">{index + 1}.</td>
                        <td className="border-bottom border-right title">
                          {question.class_name}({question.name})
                        </td>
                        <td className="border-bottom border-right text-center assessment">
                          {question.result_id === "RESULT_CIRCLE"
                            ? "○"
                            : question.result_id === "RESULT_SLASH"
                            ? "/"
                            : question.result_id === "RESULT_CROSS"
                            ? "x"
                            : question.result_id === "RESULT_TRIANGLE"
                            ? "△"
                            : " "}{" "}
                        </td>
                        <td className="border-bottom text-center">
                          {question.comment?.split("\n")?.map((t: string, index: number) => {
                            return <p key={index}>{t}</p>
                          }) || "-"}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          ) : null}
          {report?.questions?.some(
            (question: IReportQuestion) => question.inspection_id === "3dcdf21e-4238-4d4c-841d-c66fff865456"
          ) ? (
            <table>
              <thead>
                <tr className="border-bottom">
                  <th className="border-right"></th>
                  <th className="border-right text-center">No</th>
                  <th className="border-right text-center">点検項目</th>
                  <th className="border-right text-center">評価</th>
                  <th className="">指導または是正措置の内容</th>
                </tr>
              </thead>
              <tbody>
                <th
                  className="section-title border-right"
                  rowSpan={
                    report?.questions?.filter(
                      (question: IReportQuestion) => question.inspection_id === "3dcdf21e-4238-4d4c-841d-c66fff865456"
                    )?.length + 1
                  }
                >
                  E. 産廃等
                </th>
                {report?.questions
                  ?.filter(
                    (question: IReportQuestion) => question.inspection_id === "3dcdf21e-4238-4d4c-841d-c66fff865456"
                  )
                  .map((question: IReportQuestion, index: number) => {
                    return (
                      <tr id={question.inspection_id} key={`question-${index}`}>
                        <td className="border-bottom border-right text-center  number">{index + 1}.</td>
                        <td className="border-bottom border-right title">
                          {question.class_name}({question.name})
                        </td>
                        <td className="border-bottom border-right text-center assessment">
                          {question.result_id === "RESULT_CIRCLE"
                            ? "○"
                            : question.result_id === "RESULT_SLASH"
                            ? "/"
                            : question.result_id === "RESULT_CROSS"
                            ? "x"
                            : question.result_id === "RESULT_TRIANGLE"
                            ? "△"
                            : " "}{" "}
                        </td>
                        <td className="border-bottom text-center">
                          {question.comment?.split("\n")?.map((t: string, index: number) => {
                            return <p key={index}>{t}</p>
                          }) || "-"}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          ) : null}

          {report?.questions?.some(
            (question: IReportQuestion) => question.inspection_id === "556d94f4-25f6-456e-8c35-b7356a12ed10"
          ) ? (
            <table>
              <thead>
                <tr className="border-bottom">
                  <th className="border-right"></th>
                  <th className="border-right text-center">No</th>
                  <th className="border-right text-center">点検項目</th>
                  <th className="border-right text-center">評価</th>
                  <th className="">指導または是正措置の内容</th>
                </tr>
              </thead>
              <tbody>
                <th
                  className="section-title border-right"
                  rowSpan={
                    report?.questions?.filter(
                      (question: IReportQuestion) => question.inspection_id === "556d94f4-25f6-456e-8c35-b7356a12ed10"
                    )?.length + 1
                  }
                >
                  F. 墜落・転落等
                </th>
                {report?.questions
                  ?.filter(
                    (question: IReportQuestion) => question.inspection_id === "556d94f4-25f6-456e-8c35-b7356a12ed10"
                  )
                  .map((question: IReportQuestion, index: number) => {
                    return (
                      <tr id={question.inspection_id} key={`question-${index}`}>
                        <td className="border-bottom border-right text-center number">{index + 1}.</td>
                        <td className="border-bottom border-right title">
                          {question.class_name}({question.name})
                        </td>
                        <td className="border-bottom border-right text-center assessment">
                          {question.result_id === "RESULT_CIRCLE"
                            ? "○"
                            : question.result_id === "RESULT_SLASH"
                            ? "/"
                            : question.result_id === "RESULT_CROSS"
                            ? "x"
                            : question.result_id === "RESULT_TRIANGLE"
                            ? "△"
                            : " "}{" "}
                        </td>
                        <td className="border-bottom text-center">
                          {question.comment?.split("\n")?.map((t: string, index: number) => {
                            return <p key={index}>{t}</p>
                          }) || "-"}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          ) : null}
          {report?.questions?.some(
            (question: IReportQuestion) => question.inspection_id === "436b1be0-7d05-4c11-afb0-2b3b55e11462"
          ) ? (
            <table>
              <thead>
                <tr className="border-bottom">
                  <th className="border-right"></th>
                  <th className="border-right text-center">No</th>
                  <th className="border-right text-center">点検項目</th>
                  <th className="border-right text-center">評価</th>
                  <th className="">指導または是正措置の内容</th>
                </tr>
              </thead>
              <tbody>
                <th
                  className="section-title border-right"
                  rowSpan={
                    report?.questions?.filter(
                      (question: IReportQuestion) => question.inspection_id === "436b1be0-7d05-4c11-afb0-2b3b55e11462"
                    )?.length + 1
                  }
                >
                  G. 飛来・落下
                </th>
                {report?.questions
                  ?.filter(
                    (question: IReportQuestion) => question.inspection_id === "436b1be0-7d05-4c11-afb0-2b3b55e11462"
                  )
                  .map((question: IReportQuestion, index: number) => {
                    return (
                      <tr id={question.inspection_id} key={`question-${index}`}>
                        <td className="border-bottom border-right text-center number">{index + 1}.</td>
                        <td className="border-bottom border-right title">
                          {question.class_name}({question.name})
                        </td>
                        <td className="border-bottom border-right text-center assessment">
                          {question.result_id === "RESULT_CIRCLE"
                            ? "○"
                            : question.result_id === "RESULT_SLASH"
                            ? "/"
                            : question.result_id === "RESULT_CROSS"
                            ? "x"
                            : question.result_id === "RESULT_TRIANGLE"
                            ? "△"
                            : " "}{" "}
                        </td>
                        <td className="border-bottom text-center">
                          {question.comment?.split("\n")?.map((t: string, index: number) => {
                            return <p key={index}>{t}</p>
                          }) || "-"}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          ) : null}
          {report?.questions?.some(
            (question: IReportQuestion) => question.inspection_id === "0c7cf631-48dc-4789-a57d-74723d139bd1"
          ) ? (
            <table>
              <thead>
                <tr className="border-bottom">
                  <th className="border-right"></th>
                  <th className="border-right text-center">No</th>
                  <th className="border-right text-center">点検項目</th>
                  <th className="border-right text-center">評価</th>
                  <th className="">指導または是正措置の内容</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    className="section-title border-right"
                    rowSpan={
                      report?.questions?.filter(
                        (question: IReportQuestion) => question.inspection_id === "0c7cf631-48dc-4789-a57d-74723d139bd1"
                      )?.length + 1
                    }
                  >
                    H. 火災・爆発
                  </th>
                </tr>
                {report?.questions
                  ?.filter(
                    (question: IReportQuestion) => question.inspection_id === "0c7cf631-48dc-4789-a57d-74723d139bd1"
                  )
                  .map((question: IReportQuestion, index: number) => {
                    return (
                      <tr id={question.inspection_id} key={`question-${index}`}>
                        <td className="border-bottom border-right text-center number">{index + 1}.</td>
                        <td className="border-bottom border-right title">
                          {question.class_name}({question.name})
                        </td>
                        <td className="border-bottom border-right text-center assessment">
                          {question.result_id === "RESULT_CIRCLE"
                            ? "○"
                            : question.result_id === "RESULT_SLASH"
                            ? "/"
                            : question.result_id === "RESULT_CROSS"
                            ? "x"
                            : question.result_id === "RESULT_TRIANGLE"
                            ? "△"
                            : " "}{" "}
                        </td>
                        <td className="border-bottom text-center">
                          {question.comment?.split("\n")?.map((t: string, index: number) => {
                            return <p key={index}>{t}</p>
                          }) || "-"}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          ) : null}
          {report?.questions?.some(
            (question: IReportQuestion) => question.inspection_id === "1df459e5-db25-49ec-958b-f09274c152be"
          ) ? (
            <table>
              <thead>
                <tr className="border-bottom">
                  <th className="border-right"></th>
                  <th className="border-right text-center">No</th>
                  <th className="border-right text-center">点検項目</th>
                  <th className="border-right text-center">評価</th>
                  <th className="">指導または是正措置の内容</th>
                </tr>
              </thead>
              <tbody>
                <th
                  className="section-title border-right"
                  rowSpan={
                    report?.questions?.filter(
                      (question: IReportQuestion) => question.inspection_id === "1df459e5-db25-49ec-958b-f09274c152be"
                    )?.length + 1
                  }
                >
                  I. その他作業
                </th>
                {report?.questions
                  ?.filter(
                    (question: IReportQuestion) => question.inspection_id === "1df459e5-db25-49ec-958b-f09274c152be"
                  )
                  .map((question: IReportQuestion, index: number) => {
                    return (
                      <tr id={question.inspection_id} key={`question-${index}`}>
                        <td className="border-bottom border-right text-center number">{index + 1}.</td>
                        <td className="border-bottom border-right title">
                          {question.class_name}({question.name})
                        </td>
                        <td className="border-bottom border-right text-center assessment">
                          {question.result_id === "RESULT_CIRCLE"
                            ? "○"
                            : question.result_id === "RESULT_SLASH"
                            ? "/"
                            : question.result_id === "RESULT_CROSS"
                            ? "x"
                            : question.result_id === "RESULT_TRIANGLE"
                            ? "△"
                            : " "}{" "}
                        </td>
                        <td className="border-bottom text-center">
                          {question.comment?.split("\n")?.map((t: string, index: number) => {
                            return <p key={index}>{t}</p>
                          }) || "-"}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          ) : null}
          <table className="pdf-comment">
            <tr className="col-12">
              <th className="border-bottom border-right col-10">総評</th>
              <th colSpan={2} className="border-bottom col-2 text-center">
                次回パトロール予定日
              </th>
            </tr>
            <tr className="border-bottom-none col-12">
              <td rowSpan={3} className="border-right col-2">
                {comment.map((commentObject: IReportComments, index: number) => {
                  const isLastItem = index === comment.length - 1
                  const className = isLastItem ? "last-comment" : ""
                  return (
                    <p key={`comment-${index}`} className={className}>
                      <span className="">記入者: {commentObject.author}</span>
                      <br />
                      {commentObject.comment}
                    </p>
                  )
                })}
              </td>
              <td colSpan={2} className="border-bottom col-2 text-center">
                &nbsp;年&nbsp;月&nbsp;日(予定)
              </td>
            </tr>
            <tr className="border-bottom-none">
              <th className="border-bottom border-right text-center col-1">現場</th>
              <th className="border-bottom text-center col-1">パトロール者</th>
            </tr>
            <tr className=" col-12">
              <td className="border-right text-center">&#12958;</td>
              <td className="text-center" style={{ height: "25px" }}>
                &#12958;
              </td>
            </tr>
          </table>
          <table className="pdf-comment">
            <tr className="col-12">
              <th>現場からの要望事項</th>
            </tr>
            <tr>
              <td rowSpan={3} className="col-2">
                {request_comment.map((request_commentObject: IReportComments, index: number) => {
                  const isLastItem = index === request_comment.length - 1
                  const className = isLastItem ? "last-comment" : ""
                  return (
                    <p key={`request_comment-${index}`} className={className}>
                      <span className="">記入者: {request_commentObject.author}</span>
                      <br />
                      {request_commentObject.comment}
                    </p>
                  )
                })}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  )
}

export default App
