import React, { useState, useEffect, useReducer } from "react"
import { Link } from "react-router-dom"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { BsFillArrowUpSquareFill, BsFillArrowDownSquareFill } from "react-icons/bs"
import Accordion from "react-bootstrap/Accordion"
import { IReport, IReportSearch, IOption, ISortType } from "../../lib/interface"
import * as api from "../../lib/api"
import FormSearch from "./_form-search"
import Show from "./_show"
import DeleteModal from "./_delete_modal"
import { errorHandle } from "../../lib/system"

enum Progress {
  Preparing,
  Attempting,
}

const GET_REPORTS_OFFSET = 50

//検索フォームのデフォルト値を設定
const initialReportSearch = {
  job_category: "",
  job_number: "",
  job_code1: "",
  job_code2: "",
  job_date_start: "",
  job_date_end: "",
  weather_id: new Array<string>(),
  construction: "",
  contract_id: new Array<string>(),
  contract_amount_start: "",
  contract_amount_end: "",
  department_id: new Array<string>(),
  office_name: "",
  construction_id: new Array<string>(),
  construction_detail_id: new Array<string>(),
  inspector: "",
  department_manager: "",
  section_manager: "",
  project_manager: "",
  operators: new Array<string>(),
  construction_company: "",
  partners: "",
  comment: "",
  request_comment: "",
  inspections: new Array<string>(),
}

type ACTIONTYPE =
  | { type: "JOB_CATEGORY"; payload: string }
  | { type: "JOB_NUMBER"; payload: string }
  | { type: "JOB_CODE1"; payload: string }
  | { type: "JOB_CODE2"; payload: string }
  | { type: "JOB_DATE_START"; payload: string }
  | { type: "JOB_DATE_END"; payload: string }
  | { type: "WEATHER_ID"; payload: string; list: IOption[] }
  | { type: "CONSTRUCTION"; payload: string }
  | { type: "CONTRACT_ID"; payload: string; list: IOption[] }
  | { type: "CONTRACT_AMOUNT_START"; payload: string }
  | { type: "CONTRACT_AMOUNT_END"; payload: string }
  | { type: "DEPARTMENT_ID"; payload: string; list: IOption[] }
  | { type: "CHECK_ALL_DEPARTMENTS_ID"; payload: string[]; list: IOption[] }
  | { type: "OFFICE_NAME"; payload: string }
  | { type: "CONSTRUCTION_ID"; payload: string; list: IOption[] }
  | { type: "CONSTRUCTION_DETAIL_ID"; payload: string; list: IOption[] }
  | { type: "INSPECTOR"; payload: string }
  | { type: "DEPARTMENT_MANAGER"; payload: string }
  | { type: "SECTION_MANAGER"; payload: string }
  | { type: "PROJECT_MANAGER"; payload: string }
  | { type: "OPERATOR_ID"; payload: string; list: IOption[] }
  | { type: "CHECK_ALL_OPERATORS_ID"; payload: string[]; list: IOption[] }
  | { type: "CONSTRUCTION_COMPANY"; payload: string }
  | { type: "PARTNER"; payload: string }
  | { type: "COMMENT"; payload: string }
  | { type: "REQUEST_COMMENT"; payload: string }
  | { type: "INSPECTION_ID"; payload: string; list: IOption[] }
  | { type: "CHECK_ALL_INSPECTION_ID"; payload: string[]; list: IOption[] }

const reducerFunc: React.Reducer<IReportSearch, ACTIONTYPE> = (
  reportSearch: IReportSearch,
  action: ACTIONTYPE
): IReportSearch => {
  switch (action.type) {
    // JOB番号 上2桁が変更された時
    case "JOB_CATEGORY":
      return { ...reportSearch, job_category: action.payload }
    // JOB番号 下11桁が変更された時
    case "JOB_NUMBER":
      return { ...reportSearch, job_number: action.payload }
    case "JOB_CODE1":
      return { ...reportSearch, job_code1: action.payload }
    case "JOB_CODE2":
      return { ...reportSearch, job_code2: action.payload }
    // 年月日が変更された時
    case "JOB_DATE_START":
      return { ...reportSearch, job_date_start: action.payload }
    // 年月日が変更された時
    case "JOB_DATE_END":
      return { ...reportSearch, job_date_end: action.payload }
    // 天候が変更された時
    case "WEATHER_ID": {
      const weathers = action.list?.map((weather: IOption) => {
        return weather.id
      })
      if (weathers.includes(action.payload) || action.payload === "blank") {
        let updateWeathers = []
        // desabledにされた場合
        if (
          reportSearch.weather_id?.find((weather: string) => {
            return weather === action.payload
          })
        ) {
          updateWeathers = reportSearch.weather_id?.filter((weather: string) => {
            return weather !== action.payload
          })
          return { ...reportSearch, weather_id: updateWeathers }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            weather_id: [...(reportSearch?.weather_id || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    // 工事名が変更された時
    case "CONSTRUCTION":
      return { ...reportSearch, construction: action.payload }
    // 請負区分が変更された時
    case "CONTRACT_ID": {
      const contracts = action.list?.map((contract: IOption) => {
        return contract.id
      })
      if (contracts.includes(action.payload) || action.payload === "blank") {
        let updateContracts = []
        // desabledにされた場合
        if (
          reportSearch.contract_id?.find((contract: string) => {
            return contract === action.payload
          })
        ) {
          updateContracts = reportSearch.contract_id.filter((contract: string) => {
            return contract !== action.payload
          })
          return { ...reportSearch, contract_id: updateContracts }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            contract_id: [...(reportSearch.contract_id || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    // 請負金額が変更された時
    case "CONTRACT_AMOUNT_START":
      return { ...reportSearch, contract_amount_start: action.payload }
    case "CONTRACT_AMOUNT_END":
      return { ...reportSearch, contract_amount_end: action.payload }
    // 部門が変更された時
    case "DEPARTMENT_ID": {
      const departments = action.list?.map((department: IOption) => {
        return department.id
      })
      if (departments.includes(action.payload) || action.payload === "blank") {
        let updateDepartments = []
        // desabledにされた場合
        if (
          reportSearch.department_id?.find((department: string) => {
            return department === action.payload
          })
        ) {
          updateDepartments = reportSearch.department_id.filter((department: string) => {
            return department !== action.payload
          })
          return { ...reportSearch, department_id: updateDepartments }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            department_id: [...(reportSearch.department_id || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }

    case "CHECK_ALL_DEPARTMENTS_ID": {
      return { ...reportSearch, department_id: action.payload }
    }

    // 部署が変更された時
    case "OFFICE_NAME":
      return { ...reportSearch, office_name: action.payload }
    case "CONSTRUCTION_ID": {
      const constructions = action.list?.map((construction: IOption) => {
        return construction.id
      })
      if (constructions.includes(action.payload) || action.payload === "blank") {
        let updateConstructions = []
        // desabledにされた場合
        if (
          reportSearch.construction_id?.find((construction: string) => {
            return construction === action.payload
          })
        ) {
          updateConstructions = reportSearch.construction_id.filter((construction: string) => {
            return construction !== action.payload
          })
          return { ...reportSearch, construction_id: updateConstructions }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            construction_id: [...(reportSearch.construction_id || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    // 工事種類その2が変更された時
    case "CONSTRUCTION_DETAIL_ID": {
      const constructionDetails = action.list?.map((constructionDetail: IOption) => {
        return constructionDetail.id
      })
      if (constructionDetails.includes(action.payload) || action.payload === "blank") {
        let updateConstructionDetails = []
        // desabledにされた場合
        if (
          reportSearch.construction_detail_id?.find((constructionDetail: string) => {
            return constructionDetail === action.payload
          })
        ) {
          updateConstructionDetails = reportSearch.construction_detail_id.filter((constructionDetail: string) => {
            return constructionDetail !== action.payload
          })
          return {
            ...reportSearch,
            construction_detail_id: updateConstructionDetails,
          }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            construction_detail_id: [...(reportSearch.construction_detail_id || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    // 点検者が変更された時
    case "INSPECTOR":
      return { ...reportSearch, inspector: action.payload }
    // 部長が変更された時
    case "DEPARTMENT_MANAGER":
      return { ...reportSearch, department_manager: action.payload }
    // 課長が変更された時
    case "SECTION_MANAGER":
      return { ...reportSearch, section_manager: action.payload }
    // が変更された時
    case "PROJECT_MANAGER":
      return { ...reportSearch, project_manager: action.payload }
    // 被点検者が変更された時
    case "OPERATOR_ID": {
      const operators = action.list?.map((operator: IOption) => {
        return operator.id
      })
      if (operators.includes(action.payload) || action.payload === "blank") {
        let updateOperators = []
        // disabledにされた場合
        if (
          reportSearch.operators?.find((operator: string) => {
            return operator === action.payload //同じものがあるか
          })
        ) {
          updateOperators = reportSearch.operators.filter((operator: string) => {
            return operator !== action.payload
          })
          return { ...reportSearch, operators: updateOperators }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            operators: [...(reportSearch.operators || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }

    case "CHECK_ALL_OPERATORS_ID": {
      return { ...reportSearch, operators: action.payload }
    }
    // 建築会社（統括安全衛生責任者）が変更された時
    case "CONSTRUCTION_COMPANY":
      return { ...reportSearch, construction_company: action.payload }
    // 当日入場協力会社が変更された時
    case "PARTNER":
      return { ...reportSearch, partners: action.payload }
    // 総評が変更された時
    case "COMMENT":
      return { ...reportSearch, comment: action.payload }
    // 総評が変更された時
    case "REQUEST_COMMENT":
      return { ...reportSearch, request_comment: action.payload }
    // 点検分類が変更された時
    case "INSPECTION_ID": {
      const inspections = action.list?.map((inspection: IOption) => {
        return inspection.id
      })
      if (inspections.includes(action.payload) || action.payload === "blank") {
        let updateInspections = []
        // desabledにされた場合
        if (
          reportSearch.inspections?.find((inspection: string) => {
            return inspection === action.payload
          })
        ) {
          updateInspections = reportSearch.inspections.filter((inspection: string) => {
            return inspection !== action.payload
          })
          return { ...reportSearch, inspections: updateInspections }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            inspections: [...(reportSearch.inspections || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }

    case "CHECK_ALL_INSPECTION_ID": {
      return { ...reportSearch, inspections: action.payload }
    }

    default:
      return reportSearch
  }
}

function App() {
  const [reports, setReports] = useState<IReport[]>()
  const [sortType, setSortType] = useState<ISortType>()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [offset, setOffset] = useState<number>(0)
  const [progress, setProgress] = useState(Progress.Preparing)
  const [reportSearch, reportSearchDispatch] = useReducer<React.Reducer<IReportSearch, ACTIONTYPE>>(
    reducerFunc,
    initialReportSearch
  )
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [delReportId, setDelReportId] = useState<string>("")

  useEffect(() => {
    getReports(true)
  }, [sortType])

  const getReports = async (initialize: boolean) => {
    //検索時やソート時のため、initializeの値がtrueならオフセットの値を0にする
    let offsetValue = 0
    if (initialize) {
      offsetValue = 0
    } else {
      offsetValue = offset
    }
    let json
    try {
      json = await api.getReportsDb(sortType?.type || "", sortType?.order || "", reportSearch, offsetValue)
      if (json?.data) {
        //initializeがtrueならreportをセットし直す
        if (reports && reports?.length > 0 && !initialize) {
          setReports([...reports, ...(json?.data.json || [])])
        } else {
          setReports(json?.data.json)
        }
        setTotalCount(json?.data.total_count)
        setOffset(offsetValue + GET_REPORTS_OFFSET)
      }
    } catch (err) {
      console.log(errorHandle(err))
    }
  }

  const handleSortTypeChange = (e: { currentTarget: { id: string } }) => {
    //typeが切り替わるタイミングで"asc"にする
    if (sortType?.order === "desc") {
      setSortType({ type: e.currentTarget.id, order: "asc" })
    } else {
      setSortType({ type: e.currentTarget.id, order: "desc" })
    }
  }

  return (
    <>
      <DeleteModal
        delReportId={delReportId}
        reports={reports}
        setReports={setReports}
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
      />
      <h1 className="h4">安全パトロール用紙一覧</h1>
      <Accordion className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>絞り込み</Accordion.Header>
          <Accordion.Body className="bg-light">
            <FormSearch
              getReports={getReports}
              reportSearchDispatch={reportSearchDispatch}
              reportSearch={reportSearch}
              Progress={Progress}
              progress={progress}
              setProgress={setProgress}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="sticky-top">
        <Row className="text-center fw-bold align-items-center py-2 bg-head small">
          <Col>部門</Col>
          <Col>JOB番号</Col>
          <Col>
            <Link
              to="#"
              onClick={handleSortTypeChange}
              className={`link ${sortType?.type === "job_date" ? "active" : ""}`}
              id="job_date"
            >
              {sortType?.type === "job_date" && sortType?.order === "desc" ? (
                <BsFillArrowDownSquareFill className="me-1 mb-1 text-danger" size="20" />
              ) : (
                <BsFillArrowUpSquareFill className="me-1 mb-1" size="20" />
              )}
            </Link>
            年月日
          </Col>
          <Col>工事名</Col>
          <Col>
            <Link
              to="#"
              onClick={handleSortTypeChange}
              className={`link ${sortType?.type === "construction_period_start" ? "active" : ""}`}
              id="construction_period_start"
            >
              {sortType?.type === "construction_period_start" && sortType?.order === "desc" ? (
                <BsFillArrowDownSquareFill className="me-1 mb-1 text-danger" size="20" />
              ) : (
                <BsFillArrowUpSquareFill className="me-1 mb-1" size="20" />
              )}
            </Link>
            工期開始日
          </Col>
          <Col>
            <Link
              to="#"
              onClick={handleSortTypeChange}
              className={`link ${sortType?.type === "construction_period_end" ? "active" : ""}`}
              id="construction_period_end"
            >
              {sortType?.type === "construction_period_end" && sortType?.order === "desc" ? (
                <BsFillArrowDownSquareFill className="me-1 mb-1 text-danger" size="20" />
              ) : (
                <BsFillArrowUpSquareFill className="me-1 mb-1" size="20" />
              )}
            </Link>
            工期完了日
          </Col>
          <Col>
            <Link
              to="#"
              onClick={handleSortTypeChange}
              className={`link ${sortType?.type === "update_date" ? "active" : ""}`}
              id="update_date"
            >
              {sortType?.type === "update_date" && sortType?.order === "desc" ? (
                <BsFillArrowDownSquareFill className="me-1 mb-1 text-danger" size="20" />
              ) : (
                <BsFillArrowUpSquareFill className="me-1 mb-1" size="20" />
              )}
            </Link>
            更新日
          </Col>
          <Col className="d-none d-md-block"></Col>
        </Row>
      </div>
      {reports?.map((report: IReport, index: number) => {
        return (
          <Show
            key={`report-${index}`}
            report={report}
            reports={reports}
            setReports={setReports}
            index={index}
            setDelReportId={setDelReportId}
            setDeleteModalShow={setDeleteModalShow}
          />
        )
      })}
      <div className="d-flex justify-content-start gap-4">
        {totalCount > offset && (
          <Button onClick={() => getReports(false)} className="btn-sm btn-block btn-primary mt-2">
            次へ
          </Button>
        )}
      </div>
    </>
  )
}

export default App
