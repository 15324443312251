import { IReport, IOption, IReportQuestion, IOperator } from "@/lib/interface"
import ExcelJS from "exceljs"
import { TFunction } from "i18next"

export const changeDateString = (date: Date): string => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}`
}

export const errorHandle = (err: any): string => {
  if (err?.name === "AxiosError") {
    return err?.response?.data?.message
  } else if (err?.name === "Error") {
    return err.message
  } else if (err.message) {
    return err.message
  } else {
    return "error"
  }
}

export const reportCsvDownload = async (
  t: TFunction,
  report: IReport,
  weathers: IOption[],
  contracts: IOption[],
  constructions: IOption[],
  constructionDetails: IOption[],
  operators: IOption[],
  inspections: IOption[]
) => {
  const workbook = new ExcelJS.Workbook()
  workbook.addWorksheet("sheet1")
  const worksheet = workbook.getWorksheet("sheet1")

  if (!worksheet) {
    throw new Error("Worksheet not found")
  }

  //CSV用にデータ追加
  worksheet.addRow(["作成者ID", `${report?.user_name}`])
  worksheet.addRow(["部門", report?.department_name])
  worksheet.addRow(["JOB番号", `${report?.job_category || ""}-${report?.job_number || ""}`])
  worksheet.addRow(["年月日", report?.job_date])
  worksheet.addRow([
    "天候",
    weathers?.find((weather: IOption) => {
      return weather.id == report?.weather_id
    })?.name,
  ])
  worksheet.addRow(["気温", report?.temperature + "°C"])
  worksheet.addRow(["工事名", report?.construction])
  worksheet.addRow([
    "請負区分",
    contracts?.find((contract: IOption) => {
      return contract.id == report?.contract_id
    })?.name,
  ])
  worksheet.addRow(["請負金額", report?.contract_amount + "百万円"])
  worksheet.addRow(["工期開始日", report?.construction_period_start])
  worksheet.addRow(["工期完了日", report?.construction_period_end])
  worksheet.addRow(["進捗率", report?.progress_rate + "%"])
  worksheet.addRow(["部署", report?.office_name])
  worksheet.addRow([
    "工事種類",
    constructions?.find((construction: IOption) => {
      return construction.id == report?.construction_id
    })?.name,
  ])
  worksheet.addRow([
    "工事種類その2",
    constructionDetails?.find((constructionDetail: IOption) => {
      return constructionDetail.id == report?.construction_detail_id
    })?.name,
  ])
  worksheet.addRow(["点検者", report?.inspector])
  worksheet.addRow(["部長", report?.department_manager])
  worksheet.addRow(["課長", report?.section_manager])
  worksheet.addRow(["被点検者", report?.project_manager])
  const operatorsColumns: string[] = []
  // biome-ignore lint/complexity/noForEach: <explanation>
  report?.operators?.forEach((operator: IOperator) => {
    operatorsColumns.push(
      operators?.find((ope: IOption) => {
        return ope.id === operator.operator_id
      })?.name || ""
    )
    operatorsColumns.push(`${operator.operator_number}人` || "")
  })
  worksheet.addRow(["当日の作業員人数", ...operatorsColumns])
  worksheet.addRow(["建築会社（統括安全衛生責任者）", report?.construction_company])

  const partnersCount = report?.partners?.length || 0

  // 協力会社情報を個別の行に追加
  report?.partners?.forEach((partner: any, index: number) => {
    const label = partnersCount > 1 ? `当日入場協力会社${index + 1}` : "当日入場協力会社"
    worksheet.addRow([
      label,
      "協力会社名: " + (partner.name || ""),
      "職長名: " + (partner.leader_name || ""),
      "人数: " + (partner.user_count ? `${partner.user_count}人` : ""),
      "評価: " + (partner.evaluation || ""),
    ])
  })

  const inspectionsColumns: string[] = []
  report?.inspections?.forEach((inspection: string) => {
    inspectionsColumns.push(
      inspections?.find((ins: IOption) => {
        return ins.id == inspection
      })?.name || ""
    )
  })
  worksheet.addRow(["点検分類", ...inspectionsColumns])
  worksheet.addRow(["点検分類", "大項目", "チェック内容", "結果", "指導または是正内容", "画像"])
  report?.questions?.forEach(async (question: IReportQuestion) => {
    const category = question.inspection_name || question.department_name
    worksheet.addRow([
      category,
      question.class_name,
      question.name,
      t(question.result_id || ""),
      question.comment,
      question.image ? `${process.env.MEDIA_DOMAIN}${question.image}` : "",
    ])
  })
  worksheet.addRow([""])

  let commentSummary = ""
  if (Array.isArray(report?.comment)) {
    commentSummary = report.comment
      .map((comment: { author: string; comment: string }) => {
        return `記入者: ${comment.author}, 総評: ${comment.comment}`
      })
      .join("\n")
  } else if (report?.comment && typeof report.comment === "string") {
    // パースしてフォーマットを変換
    const parsedComment = parseKeyValue(report.comment)
    commentSummary = `記入者: ${parsedComment.author}, 総評: ${parsedComment.comment}`
  }

  worksheet.addRow(["総評", commentSummary])

  // 現場からの要望事項
  let requestCommentSummary = ""
  if (Array.isArray(report?.request_comment)) {
    requestCommentSummary = report.request_comment
      .map((comment: { author: string; comment: string }) => {
        return `記入者: ${comment.author}, 要望事項: ${comment.comment}`
      })
      .join("\n")
  } else if (report?.request_comment && typeof report.request_comment === "string") {
    // パースしてフォーマットを変換
    const parsedRequestComment = parseKeyValue(report.request_comment)
    requestCommentSummary = `記入者: ${parsedRequestComment.author}, 要望事項: ${parsedRequestComment.comment}`
  }

  worksheet.addRow(["現場からの要望事項", requestCommentSummary])

  // パース関数
  function parseKeyValue(str: string): { author: string; comment: string } {
    const keyValuePairs = str.split(", ").map((pair) => pair.split(": "))
    const result: { [key: string]: string } = {}
    keyValuePairs.forEach(([key, value]) => {
      result[key] = value
    })
    return { author: result.author || "", comment: result.comment || "" }
  }
  //文字コードをexel用に変換
  const uint8Array = await workbook.xlsx.writeBuffer()
  const blob = new Blob([uint8Array], {
    type: "application/octet-binary",
  })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = "total_check_sheet.xlsx"
  a.click()
  a.remove()
}
